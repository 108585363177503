import type { ThemeConfig } from "antd";

export const themeAntd: ThemeConfig = {
  hashed: false,
  components: {
    Menu: {
      itemHoverBg: "#262626",
      itemHoverColor: "#FA541C",
      itemActiveBg: "#262626"
    }
  },
  token: {
    colorPrimary: "#FA541C",
    colorLink: "#FA541C",
    borderRadius: 0
  }
};
