export const accessNames = {
  fullAccess: "fullAccess",
  user: "user",
  application: "appeal",
  notification: "notification",
  employee: "employee",
  role: "role",
  reminder: "reminder",
  log: "log",
  settings: "settings",
  marketing: "marketing"
};
