import type {IFiltersUser} from "../../../api/dto/users/IUser";
import {changeDataFormatToISO} from "../../../common/helpers/changeDateFormatToISO";

export const generateODataQueryUser = (filterData: IFiltersUser) => {
    const sizePage = 10;
    let oDataQuery = "$count=true";

    if (filterData.pagination) {
        const skipValue = filterData.pagination * sizePage - sizePage;
        oDataQuery += `&$skip=${skipValue}&$top=${sizePage}`;
    }

    if (filterData.name || filterData.date) {
        const filters: string[] = [];

        // +7 (777) 777-77-77 - проверка
        if (/^\+\d\s\(\d{3}\)\s\d{3}\-\d{2}\-\d{2}$/g.test(filterData.name)) {
            filters.push(`contains(phone, '${encodeURIComponent(filterData.name.replace(/[\s()\-+]/g, "").toLowerCase())}')`);
        } else if (/^[0-9-()\s]+$/.test(filterData.name) || filterData.name.includes("+7")) {
            filters.push(`contains(phone, '${encodeURIComponent(filterData.name.replace(/[\s()\-+]/g, "").toLowerCase())}')`);
        } else if (filterData.name) {
            filters.push(`(contains(tolower(lastName), '${encodeURIComponent(filterData.name.split(" ")[0].toLowerCase()
                .replace(/'/g, '%27%27'))}')
            or contains(tolower(firstName), '${encodeURIComponent(filterData.name.split(" ")[0].toLowerCase().replace(/'/g, '%27%27'))}')
            or contains(tolower(email), '${encodeURIComponent(filterData.name.toLowerCase().replace(/'/g, '%27%27'))}'))`);
        }

        if (filterData.date) {
            filters.push(`(createdUtc ge ${changeDataFormatToISO(filterData.date[0], true)}
      and createdUtc le ${changeDataFormatToISO(filterData.date[1])})`);
        }

        oDataQuery += `&$filter=${filters.join(" and ")}`;
    }

    if (filterData.sorting) {
        oDataQuery += `&$orderby=${filterData.sorting}`;
    } else {
        oDataQuery += `&$orderby=createdUtc desc`;
    }

    return oDataQuery;
};
