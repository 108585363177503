import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { StateType } from "../../core/redux/store";
import { routePaths } from "../../common/constants/routePaths";

export const NotFound: FC = () => {
  const navigate = useNavigate();
  const { authData } = useSelector((state: StateType) => state.authData);

  useEffect(() => {
    if (authData.access_token) {
      navigate(-1);
    } else {
      navigate("/" + routePaths.sign.auth);
    }
  }, []);

  return null;
};
