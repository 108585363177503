import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {StateType} from "./core/redux/store";
import {ReactTitle} from "react-meta-tags";
import {ConfigProvider} from "antd";
import {getToken} from "./common/helpers/getToken";
import {AuthLayout} from "./components/layoutComponents/AuthLayout";
import {routePaths} from "./common/constants/routePaths";
import {AuthRouting} from "./pages/authRouting";
import {PrivateLayout} from "./components/layoutComponents/PrivateLayout";
import {PrivateRouting} from "./pages/privateRouting";
import {Help} from "./pages/help/Help";
import {getStorageLanguage} from "./common/helpers/storageLanguage";
import {localeAntd} from "./common/constants/languages";
import {useTranslation} from "react-i18next";
import {intervalRefreshHandler} from "./modules/authorization/helpers/refreshHelper";
import {beforeUnloadObj} from "./common/helpers/storeChangeController";
import {themeAntd} from "./assets/theme/themeAntd";
import {getAllEmployees, getEmployeeId} from "./modules/employees/employeesActionAsync";
import {NotFound} from "./pages/notFound/NotFound";
import {logoutAllTabs, logoutChannel} from "./api/baseRequest";

export const App = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {t} = useTranslation();
    const {authData, userInfo} = useSelector((state: StateType) => state.authData);
    const employee = useSelector((state: StateType) => state.employees.employee);
    const [locale, setLocal] = useState(localeAntd());
    const [title, setTitle] = useState<string>(t("common.adminPanel"));

    useEffect(() => {
        logoutAllTabs();

        return () => {
            logoutChannel.close();
        };
    }, []);

    useEffect(() => {
        setLocal(localeAntd(getStorageLanguage()));
    }, [getStorageLanguage()]);

    useEffect(() => {
        authData.access_token && intervalRefreshHandler();
    }, []);

    // useEffect(() => {
    //     beforeUnloadObj.isRemember = userInfo.remember;
    //     beforeUnloadObj.userInfo = userInfo || {};
    //     beforeUnloadObj.userAuthInfo = authData || {};
    //     window.addEventListener("beforeunload", beforeUnloadObj.beforeUnload);
    //
    //     return () => {
    //         window.removeEventListener("beforeunload", beforeUnloadObj.beforeUnload);
    //     };
    // }, [userInfo, authData.access_token]);

    useEffect(() => {
        if (userInfo.sub) {
            dispatch(getEmployeeId(userInfo.sub));
        }
    }, [userInfo.sub]);

    useEffect(() => {
        const userEmail = employee?.email;
        if (!userInfo || !userEmail) {

            return;
        }
        if (employee?.name) {
            setTitle(`${t("common.adminPanel")} - ${employee?.name} (${userEmail})`);
        } else if (userEmail) {
            setTitle(`${t("common.adminPanel")} - (${userEmail})`);
        } else {
            setTitle(t("common.adminPanel"));
        }
    }, [location.pathname, employee?.email]);

    return (
        <ConfigProvider theme={themeAntd} locale={locale}>
            <ReactTitle title={title}/>
            <Routes>
                <Route path="/help" element={<Help/>}/>
                <Route path={routePaths.base} element={getToken() ? <PrivateLayout/> : <AuthLayout/>}>
                    {getToken() ? (
                        <Route path={"*"} element={<PrivateRouting accessPolicies={userInfo.policy}/>}/>
                    ) : (
                        <>
                            <Route path={routePaths.sign.auth + "/*"} element={<AuthRouting/>}/>
                            <Route path={"/"} element={<Navigate to={routePaths.sign.auth} replace/>}/>
                            <Route path={"*"} element={<NotFound/>}/>
                        </>
                    )}
                </Route>
            </Routes>
        </ConfigProvider>
    );
};
